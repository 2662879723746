@import "~antd/dist/antd.css";

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

.App {
  position: relative;
  width: 100%;
  height: 100vh;
}
.main-spinner-suspense {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.main-layout {
  background: #dadada;
}
.main-layout .ant-layout {
  /* height: 100vh; */
  background-color: #FBFBFB;
}
.main-layout-wrapper {
  padding-left: 0;
}
@media only screen and (min-width: 768px) {
  .main-layout-wrapper {
    padding-left: 84px;
  }
}

.main-layout .main-layout-inner {
  max-width: 1024px;
  margin: 0 auto;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.ant-layout-sider .ant-menu {
  font-size: 18px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #fff;
}
.ant-menu-item a {
  color: rgba(0, 0, 0, 1);
}
.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: #00CCB2;
  font-weight: 700;
}
.ant-layout-sider-light, .ant-menu-light {
  background: #FCFCFC;
}
.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
  opacity: 0;
  transform: scaleY(0);
  border-right: 0px;
}
.sider-logo {
  background: #00CCB2;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  padding-left: 24px;
}
.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-inline-collapsed .ant-menu-item:not(:last-child) {
  margin-top: 24px;
  margin-bottom: 24px;
}
.ant-table-thead .ant-table-cell {
  background-color: #F8F8F8;
}

.page-content-layout {
  /* margin: 0 24px; */
  margin: 0;
  padding-bottom: 64px;
}

.card-room-rate {
  border: 1px solid #BCBCBC;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 24px;
}

.ant-modal.extra-width {
  width: 100% !important;
  max-width: 1024px;
  /* padding-top: 24px; */
}
.ant-modal.width-768 {
  width: 100% !important;
  max-width: 768px;
}
.ant-select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: transparent;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #C6C6C6;
}

.checkbox-reason {
  border: 2px solid #00CCB2;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}
.checkbox-reason .ant-checkbox-wrapper span {
  font-weight: 700;
  color: #00CCB2;
  font-size: 16px;
}

.avatar-uploader .ant-upload {
  width: 100%;
  height: 128px;
}

.monthly-night-audit .ant-radio-group {
  display: none;
}
.chat-qiscus.ant-modal {
  padding-top: 0;
  padding-bottom: 0;
}
.chat-qiscus .ant-modal-body {
  height: 94vh;
  padding: 0;
}

/**
 * test changes
 */
.ant-btn {
  /* border-radius: 6px; */
  font-weight: 600;
}

/**
 * typography
 */
.text-400 {
  font-weight: 400;
}
.text-500 {
  font-weight: 500;
}
.text-600 {
  font-weight: 600;
}
.text-700 {
  font-weight: 700;
}

.page-content-container {
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  margin: 6px 0;
}
.page-content-inner {
  border: 1px solid #F8F8F8;
  border-radius: 8px;
}
.page-content-detail {
  padding: 16px;
}
.row-detail {
  row-gap: 20px !important;
}
.filter-content,
.alert-double-booked {
  padding: 8px;
}
.section-detail-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 170%;
  color: #474747;
  margin-bottom: 16px;
}
.tab-guest-content {
  display: none;
}
.tab-guest-content.selected {
  display: block;
}

/**
 * new design panel datepicker
 */
.custom-panel-datepicker {
  min-height: 640px;
}
.date-display-selector {
  font-size: 12px;
  font-weight: 600;
  color: #5C5C5C;
  position: relative;
  text-align: right;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.caret-icon {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  transition: all .3s;
}
.date-display-selector .caret-icon img {
  width: 9px;
}
.date-display-selector.expanded .caret-icon {
  transform: rotate(180deg);
}
#custom-panel-datepicker .ant-picker {
  visibility: hidden;
}
#custom-panel-datepicker .ant-picker-dropdown.ant-picker-dropdown-range,
#custom-panel-datepicker .ant-picker-dropdown {
  top: 28px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
#custom-panel-datepicker .ant-calendar-picker-container {
  animation:none !important;
  transition: none !important;
} 
#custom-panel-datepicker .ant-picker-panel-container {
  transition: none !important;
  box-shadow: none !important;
}
#custom-panel-datepicker .ant-picker-range-arrow {
  display: none;
}
#custom-panel-datepicker .ant-picker-panels .ant-picker-panel,
#custom-panel-datepicker .ant-picker-panels .ant-picker-date-panel {
  width: 300px;
}
#custom-panel-datepicker .ant-picker-date-panel .ant-picker-content {
  width: 272px;
}
#custom-panel-datepicker .ant-picker-panel {
  border-radius: 12px;
  border: 2px solid #F8F8F8;
}
#custom-panel-datepicker .ant-picker-panel:first-child {
  margin-right: 0px;
  margin-bottom: 20px;
}
#custom-panel-datepicker .ant-picker-panel .ant-picker-header {
  border: none;
}
#custom-panel-datepicker .ant-picker-header-super-next-btn,
#custom-panel-datepicker .ant-picker-header-super-prev-btn {
  display: none;
}
#custom-panel-datepicker .ant-picker-cell .ant-picker-cell-inner {
  font-size: 12px;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
}
#custom-panel-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner,
#custom-panel-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner,
#custom-panel-datepicker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
#custom-panel-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
#custom-panel-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  border-radius: 50%;
  background-color: #29A4A4;
}

#custom-panel-datepicker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 50%;
  border: 1px solid #29A4A4;
}
#custom-panel-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
#custom-panel-datepicker .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
#custom-panel-datepicker .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: rgba(41, 164, 164, 0.24);
}
#custom-panel-datepicker .ant-picker-cell::before {
  height: 32px;
}
#custom-panel-datepicker .ant-picker-cell {
  padding: 1px 0;
}
.ant-picker-panel-container .ant-picker-panels {
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .custom-panel-datepicker {
    min-height: 320px;
  }
  #custom-panel-datepicker .ant-picker-panel:first-child {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .date-display-selector {
    font-size: 14px;
  }
  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: unset;
  }
}

.btn-solid-pelarous {
  color: white !important;
  background: #29A4A4 !important;
  border-radius: 8px;
  border: 1px solid #29A4A4;
}
.btn-solid-pelarous:hover, .btn-solid-pelarous:active, .btn-solid-pelarous:focus {
  background: #1F7F7F !important;
  color: white !important;
  border: 1px solid #1F7F7F;
}
.btn-solid-pelarous:disabled {
  opacity: 0.48;
}
.btn-outline-pelarous {
  color: #29A4A4 !important;
  background: white !important;
  border-radius: 8px;
  border: 1px solid #29A4A4;
}
.btn-outline-pelarous:hover, .btn-outline-pelarous:active, .btn-outline-pelarous:focus {
  background: white !important;
  color: #1F7F7F !important;
  border: 1px solid #1F7F7F !important;
}
.btn-outline-pelarous:disabled {
  opacity: 0.48;
}

.btn-solid-red {
  color: #F22E2E !important;
  background: #FDE6E6 !important;
  border-radius: 8px;
  border: 1px solid #FDE6E6;
}
.btn-solid-red:hover, .btn-solid-red:active, .btn-solid-red:focus {
  border: 1px solid  #F22E2E;
  opacity: 0.7;
}

.btn-solid-chalkywhite {
  color: #E05151 !important;
  background: #F8F8F8 !important;
  border-radius: 8px;
}
.btn-solid-chalkywhite:hover, .btn-solid-chalkywhite:active, .btn-solid-chalkywhite:focus {
  background: #AEAEAE !important;
  color: #E05151 !important;
  border: none;
}
.btn-solid-chalkywhite:disabled {
  opacity: 0.48;
}
.btn-medium {
  font-size: 14px;
}

.table-striped-rows tr:nth-child(odd) td {
  background-color: #29A4A40A;
}

.alert-double-booked {
  position: relative;
}
.alert-double-booked .ant-carousel .slick-dots {
  bottom: -28px;
}
.alert-double-booked .ant-carousel .slick-dots li button {
  background: #333333;
}
.alert-double-booked .ant-carousel .slick-dots li.slick-active button {
  background: #707070;
}
.alert-double-booked .ant-carousel .slick-dots li,
.alert-double-booked .ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.alert-double-booked .wrapper-arrow {
  opacity: 0;
}
.alert-double-booked:hover .wrapper-arrow {
  opacity: 1;
}
